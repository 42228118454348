import React, { useState } from "react";
import AboutSection from "../img/about-section.jpg";

const sectionStyles = {
  width: "100%",
  display: "flex",
  height: "50vh",
  minHeight: "450px",
};


const aboutImage = {
  width: "100%",
  minHeight: "500px",
  backgroundImage: `url(${AboutSection})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};

// const textStyles = {
//   color: "black",
//   fontSize: "2em",
//   padding: "15px",
//   textAlign: "right",
// };

const aboutText = {
  display: 'flex',
  flexDirection: 'column',
  color: "black",
  fontSize: "2em",
  padding: "15px",
  paddingRight: "25px",
  textAlign: "right",
}

const titleText = {
  fontStyle: "italic",
  fontWeight: "bold",
  marginBottom: "2em",
}

const overlayStyles = {
  backgroundColor: "rgba(36, 145, 235, 0.9)",
  height: "100%",
  width: "100%",
  padding: "10px",
  fontSize: "1.2em"
};

const About = () => {
  const [showList, setShowList] = useState(false);

  return (
    <div class="about-section-main" style={sectionStyles}>
      <div class="about-section-main" style={sectionStyles}>
        <div class="about-section-image" style={aboutImage}>
          {!showList ? null : (
            <div style={overlayStyles}>
              <ol class="overlay-list">
                <li>1. We’re 100% locally owned and operated.</li>
                <li>2. We average 5 stars on Google.</li>
                <li>
                  3. We have an online portal where you can view upcoming and past
                  work, request new work, and print receipts.
                </li>
                <li>
                  4. We have full WorkSafe and liability insurance (up to
                  $5,000,000).
                </li>
                <li>
                  {" "}
                  5. We have a great referral program where you and your neighbours
                  can earn months of free work.
                </li>
                <li>
                  6. We always text when we’re on the way, or—for recurring work
                  like lawn mowing—we assign you a particular day so you know
                  when to expect us.
                </li>
                <li>
                  {" "}
                  7. We have a bold guarantee: If you aren’t 100% satisfied with
                  our work and we can’t make it right, we will pay a competitor
                  of your choice to complete the job.
                </li>
              </ol>
            </div>
          )}
        </div>
        <div class="about-section-text" style={aboutText}>
          <div style={titleText}>We're not like the other lawn companies.</div>
          <br />
          <a onClick={() => setShowList(!showList)}>Here's why.</a>
        </div>
      </div>
    </div>
  );
};

export default About;
