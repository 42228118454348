import React from "react";

const Review = ({
  text,
  reviewer,
}) => {

  const openReviews = () => {
    window.open(
      "https://www.google.com/search?rlz=1C5CHFA_enCA779CA779&sxsrf=ALeKk01YnqpD2MScX4jSV9QL3yi6xhvsbQ%3A1613315965390&ei=fT8pYKSEF9P19APz2JuIAw&q=xenith+lawn+care&oq=xenith+lawn+care&gs_lcp=Cgdnd3Mtd2l6EAMyDQguEMcBEK8BECcQkwIyBAgjECcyBAgjECcyAggmOgoILhDHARCvARAnOgUILhCRAjoFCAAQkQI6BQgAELEDOggIABCxAxCDAToLCC4QxwEQrwEQkQI6CAguEMcBEKMCOggILhCxAxCDAToFCC4QsQM6AggAOgQIABAKOggILhDHARCvAToICAAQFhAKEB5Q2iJYmC5g9y5oAHAAeACAAYoBiAHqCpIBBDE2LjGYAQCgAQGqAQdnd3Mtd2l6wAEB&sclient=gws-wiz&ved=0ahUKEwjktpuo1unuAhXTOn0KHXPsBjEQ4dUDCA0&uact=5#lrd=0x5485d184a61a5361:0xb6c7909191764fc4,1,,,"
    );
  }
  return (
    <div
      class={"review-button"}
      onClick={openReviews}
      style={{
        padding: "0px 20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "200px",
      }}
    >
      <h1 style={{ textAlign: "center", fontFamily: "serif" }}>&#8220;</h1>
      <div style={{ marginBottom: "10px" }}>
        <div
          class={"review-text"}
          style={{ fontStyle: "italic", textAlign: "center" }}
        >
          {text}
        </div>
        <br />
        <p style={{ textAlign: "center" }}>{reviewer}</p>
      </div>
      <h1 style={{ textAlign: "center", fontFamily: "serif" }}>&#8221;</h1>
      {/* <div class={"stars"} style={{ textAlign: "center", bottom: "0px" }}>
        ⭐️ ⭐️ ⭐️ ⭐️ ⭐️
      </div> */}
    </div>
  );
};

export default Review;
