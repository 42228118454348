import React from "react";
import styled from "styled-components";
import Nav from "react-bootstrap/Nav";
import Logo from "../img/xenith-logo.png";
import Row from "react-bootstrap/Row";
import { MdMail } from "react-icons/md";
import { FaPhone } from "react-icons/fa";
import DropdownNav from "./DropdownNav.js";

const Navbar = () => {
  const [navbar, setNavbar] = React.useState(false);
  const [scrollPos, setScrollPos] = React.useState(window.pageYOffset);
  const [navbarHidden, setNavbarHidden] = React.useState(false);

  function toggleNavbar() {
    if (navbar) {
      setNavbar(false);
    } else if (!navbar) {
      setNavbar(true);
    }
  }

  const clickButton = () => {
    document.getElementsByClassName("hiddenButton")[0].click();
  };

  const hide = {
    transform: "translateY(-100px)",
    transitionDuration: ".6s",
  };

  React.useEffect(() => {
    function handleScroll() {
      setScrollPos(window.pageYOffset);
      const currentPos = window.pageYOffset;
      if (scrollPos < currentPos) {
        setNavbarHidden(true);
      } else {
        setNavbarHidden(false);
      }
    }

    // window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPos]);
  return (
    <div
      style={{
        paddingTop: "1rem",
        paddingBottom: "1rem",
        paddingLeft: "30px",
        paddingRight: "30px",
        margin: "0",
        flexWrap: "nowrap",
        width: "100%",
        backgroundColor: "white",
        position: "sticky",
        display: "block",
        top: "0",
        zIndex: "99999999998",
      }}
    >
      <Row style={{ justifyContent: "space-between", flexWrap: "nowrap" }}>
        <Nav
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexWrap: "nowrap",
          }}
          activeKey="/home"
        >
          <Nav.Item>
            <NavLogo>
              <img
                src={Logo}
                style={{ height: "50px", width: "auto" }}
                alt=""
              />
            </NavLogo>
          </Nav.Item>
          <div className="nav-links">
            <Nav.Item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "20px",
                padding: "0px",
                alignItems: "baseline",
                height: "52px",
                flexDirection: "column",
              }}
            >
              <Nav.Link
                style={{
                  padding: "0px",
                  fontWeight: "bold",
                }}
                class={"nav-link-text"}
                href="#services"
              >
                SERVICES
              </Nav.Link>
            </Nav.Item>
            <Nav.Item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "20px",
                padding: "0px",
                alignItems: "baseline",
                height: "52px",
                flexDirection: "column",
              }}
            >
              <Nav.Link
                style={{
                  padding: "0px",
                  fontWeight: "bold",
                }}
                class={"nav-link-text"}
                href="#about"
              >
                ABOUT
              </Nav.Link>
            </Nav.Item>
            <Nav.Item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "20px",
                padding: "0px",
                alignItems: "baseline",
                height: "52px",
                flexDirection: "column",
              }}
            >
              <Nav.Link
                style={{
                  padding: "0px",
                  fontWeight: "bold",
                }}
                class={"nav-link-text"}
                target="_blank"
                href="https://clienthub.getjobber.com/client_hubs/e952dac4-323f-4200-bff6-b4886ec7b867/login/new"
              >
                CLIENT LOGIN
              </Nav.Link>
            </Nav.Item>
          </div>
        </Nav>
        <Row class="nav-contact" style={{ paddingRight: "30px", alignSelf: "flex-end" }}>
          <EmailContainer
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
            class={"nav-contact"}
          >
            <p class="mobile-contact-text">
              <FaPhone />
              <a
                style={{ paddingLeft: "5px" }}
                class="phone"
                href="tel:7788260266"
              >
                778.826.0266
              </a>
            </p>
            <p class="mobile-contact-text">
              <MdMail />
              <a
                class="emailNav"
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:contact@xenith.ca"
                style={{ paddingLeft: "5px" }}
              >
                contact@xenith.ca
              </a>
            </p>{" "}
          </EmailContainer>
          <button
            style={{ marginLeft: "5px" }}
            type="button"
            name="button"
            onClick={clickButton}
            id="work-request-button-e952dac4-323f-4200-bff6-b4886ec7b867"
            class="nav-login-button"
          >
            Get Quote
          </button>
        </Row>
      </Row>

      {/* <DropdownNav>
        <div className="nav-links-mobile">
          <Nav.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "20px",
              padding: "0px",
              alignItems: "baseline",
              height: "52px",
              flexDirection: "column",
            }}
          >
            <Nav.Link
              style={{
                padding: "0px",
                fontWeight: "bold",
              }}
              class={"nav-link-text"}
              href="#services"
            >
              SERVICES
            </Nav.Link>
          </Nav.Item>
          <Nav.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "20px",
              padding: "0px",
              alignItems: "baseline",
              height: "52px",
              flexDirection: "column",
            }}
          >
            <Nav.Link
              style={{
                padding: "0px",
                fontWeight: "bold",
              }}
              class={"nav-link-text"}
              href="#about"
            >
              ABOUT
            </Nav.Link>
          </Nav.Item>
          <Nav.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "20px",
              padding: "0px",
              alignItems: "baseline",
              height: "52px",
              flexDirection: "column",
            }}
          >
            <Nav.Link
              style={{
                padding: "0px",
                fontWeight: "bold",
              }}
              class={"nav-link-text"}
              target="_blank"
              href="https://clienthub.getjobber.com/client_hubs/e952dac4-323f-4200-bff6-b4886ec7b867/login/new"
            >
              CLIENT LOGIN
            </Nav.Link>
          </Nav.Item>
          <EmailContainer
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: "10vh",
            }}
            class={"nav-contact"}
          >
            <p
              style={{
                display: "flex",
                alignItems: "baseline",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              <div style={{ paddingTop: "5px" }}>
                <FaPhone color={"#043262"} size={"1.2rem"} />
              </div>
              <a class="nav-link" href="tel:7788260266">
                778.826.0266
              </a>
            </p>
            <p
              style={{
                display: "flex",
                alignItems: "baseline",
                fontSize: "1.2rem",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              <div style={{ paddingTop: "5px" }}>
                <MdMail color={"#043262"} size={"1.2rem"} />
              </div>
              <a
                class="nav-link"
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:contact@xenith.ca"
              >
                contact@xenith.ca
              </a>
            </p>{" "}
          </EmailContainer>
          <button
            style={{ marginLeft: "5px", bottom: "20px" }}
            type="button"
            name="button"
            onClick={clickButton}
            id="work-request-button-e952dac4-323f-4200-bff6-b4886ec7b867"
          >
            Get Quote
          </button>
        </div>
      </DropdownNav> */}
    </div>
  );
};

// COMMENTS

const NavLogo = styled.div`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #2676ba;
  img {
    width: 40px;
    padding-bottom: 5px;
    margin-right: 1rem;
  }

  @media screen and (max-width: 570px) {
    margin-left: 1.5rem;
  }
`;

const StyledButton = styled.button`
  width: 10rem;
  height: 3rem;
  border: none;
  background: #2676ba;
  color: white;
  margin-left: 1rem;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0 0.25rem;
    padding: 0;
    color: #313131;
    font-size: 0.9rem;
    font-weight: 300;
  }
`;

const NavRow = styled(Row)`
  align-items: center;
  @media screen and (max-width: 700px) {
    display: none !important;
  }
`;

export default Navbar;
