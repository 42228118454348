import React from "react";
import styled from "styled-components";
import "./App.css";
import Navbar from "./components/Navbar";
import SnowRemovalForm from "./components/SnowRemovalForm";
import Hero from "./Hero";
import Services from "./components/Services";
import Lawn from "./img/lawn.jpg";
import Fertilizer from "./img/fertilizer.jpg";
import Leaves from "./img/leaves2.jpg";
import Overseed from "./img/overseed.jpg";
import Spring from "./img/spring-cleanup.jpg";
import Snow from "./img/snow-removal.jpg";
import WeedControl from "./img/weed-control.jpg";
import Package from "./img/package.jpg";
import Trimming from "./img/Hedge-Trimming.jpg";
import { GlobalStyles } from "./components/globalStyles";
import About from "./components/About";
import Reviews from "./components/Reviews";
import SectionTitle from "./components/SectionTitle";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const SERVICES = [
  {
    title: "Maintenance Package",
    description:
      "Leave your seasonal tasks to us and save 10%! Includes aeration (March), lawn mowing (April-October), and two leaf removals (November). Pay as you go via credit card. No contract required. Curious to learn more about a service? Simply scroll down and select the service you're interested in.",
    imgUrl: Package,
    full: true,
  },
  {
    title: "Fertilizer Package",
    description:
      "Enjoy a lush lawn without learning the ins-and-outs of turf nutrition — and save 10%! Includes four fertilizations and two lime applications. Pay as you go via credit card. No contract required.",
    imgUrl: Fertilizer,
    full: true,
  },
  {
    title: "Lawn Mowing",
    description:
      "From April until the end of October we mow, trim, and edge lawns on a predominately weekly schedule. Each customer is assigned a specific cutting day, and if we aren’t able to make it to your property for some reason (e.g. heavy rain), we make sure to let you know.",
    imgUrl: Lawn,
    full: false,
  },
  {
    title: "Leaf Removal",
    description:
      "In Fall and Winter we comb properties for leaves and other green waste, ensuring the lawn, boulevard, and sidewalks are blown clean. Any excess debris that will not fit in your green bin is hauled away.",
    imgUrl: Leaves,
    full: false,
  },
  {
    title: "Aeration",
    description:
      "In Spring and Fall we aerate lawns. Aeration perforates the lawn with small holes so air, water, and nutrients can penetrate the grass roots and produce a lush lawn.",
    imgUrl: Spring,
    full: false,
  },
  {
    title: "Hedge and Shrub Trimming",
    description:
      "Throughout the year we shape hedges and shrubs. After the job is complete we blow the yard clean of any remaining clippings and haul all debris to the nearest green waste facility.",
    imgUrl: Trimming,
    full: false,
  },
  {
    title: "Fertilization, Lime, and Overseeding",
    description:
      "Throughout the growing season we fertilize, lime, and spread fresh grass seed on lawns. Season specific fertilizers provide the right nutrients at the right time. Lime reduces soil acidity (thereby discouraging moss) and increases the lawn’s ability to absorb nutrients. Overseeding improves grass density and helps reduce the possibility of weeds.",
    imgUrl: Overseed,
    full: false,
  },
  // {
  //   title: "Weed Control",
  //   description:
  //     "Throughout the growing season we spray lawns with a pet- and kid-friendly product that selectively targets a wide variety of weeds (including dandelions, creeping buttercup, and moss)",
  //   imgUrl: WeedControl,
  //   full: false,
  // },
  // {
  //   title: "Snow Removal",
  //   description:
  //     "Throughout the Winter we clear snow from driveways, sidewalks, and entrances to homes. There are two snow removal models to choose from: subscription and on-call. With the subscription model, clients pay a flat monthly rate and are guaranteed service seven days a week (including holidays). With the on-call model, clients pay per visit and receive service immediately after the subscribers.",
  //   imgUrl: Snow,
  //   full: false,
  // },
];

function App() {

    // <Router>
    //   <Switch>
    //     <Route exact path="/">
    //       <Home />
    //     </Route>
    //     <Route exact path="/snow-removal">
    //       <SnowRemoval />
    //     </Route>
    //   </Switch>
    // </Router
}

function SnowRemoval() {
  return (
    <>
      <GlobalStyles />
      <Navbar />
      <SnowRemovalForm />
    </>
  );
}

function Home() {
  return (
    <>
      <GlobalStyles />
      <Navbar />
      <Hero />
      {/* <CallNow /> */}
      {/* <Contact /> */}
      <SectionTitle anchor="services" title="Services" />
      <ServicesContainer>
        {SERVICES.map((service) => {
          return (
            <Services
              service
              imageUrl={service.imgUrl}
              title={service.title}
              description={service.description}
              key={service.title}
              full={service.full}
            />
          );
        })}
      </ServicesContainer>
      <SectionTitle anchor="about" />
      <Reviews />
      <About />
    </>
  );
}

const ServicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export default App;
