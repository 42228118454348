import { createGlobalStyle } from "styled-components";
export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
/* body {
	line-height: 1;
} */
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

  html {
    font-size: 16px;
    scroll-behavior: smooth; 

    @media screen and (max-width: 1200px) {
      font-size: 15px
    }

    @media screen and (max-width: 1000px) {
      font-size: 14px
    }

    /* @media screen and (max-width: 800px) {
      font-size: 13px
    } */

    @media screen and (max-width: 600px) {
      font-size: 13px
    }

    @media screen and (max-width: 500px) {
      font-size: 12px
    }

  }

  body {
    font-family: "Roboto", BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    transition: all 0.25s linear;
  }

  h1{
    font-size: 3rem; 
    font-weight: 800;
  }
  h2 {
    font-size: 2.3rem;
    font-weight: 700; 
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 700; 
    font-family: "Roboto"
  }

  li {
    list-style: none; 
  }
  `;
