import React, { useState } from "react";
import styled from "styled-components";
import Lawn from "./img/lawn2.jpg";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { StyledButton } from "./components/CONSTANTS";

const clickButton = () => {
  document.getElementsByClassName("hiddenButton")[0].click();
};

const Hero = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <StyleContainer>
      <img src={Lawn} alt="" />
      <StyledContent>
        <h1>LEAVE YOUR LAWN TO US.</h1>
        <p>
          We take care of yards in Burnaby, East Vancouver, and New Westminster.
        </p>
        <div className="hero-buttons">
          <button
            type="button"
            style={{ width: "160px", height: "50px", fontSize: "16px", marginRight: "10px" }}
            name="button"
            onClick={clickButton}
            id="work-request-button-e952dac4-323f-4200-bff6-b4886ec7b867"
          >
            Get Quote
          </button>
          <button
            type="button"
            style={{ width: "160px", height: "50px", fontSize: "16px" }}
            onClick={toggle}
            className="modal-trigger-button"
          >
            Why Xenith?
          </button>
        </div>
        <Modal
          zIndex="99999999999"
          centered="true"
          size="xl"
          isOpen={modal}
          toggle={toggle}
        >
          <ModalBody>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <iframe
                // width="560"
                height="450"
                width="100%"
                src="https://www.youtube.com/embed/oxUpCTlXzWY?rel=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </ModalBody>
        </Modal>
      </StyledContent>
    </StyleContainer>
  );
};

const StyleContainer = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  position: relative;

  h1 {
    max-width: 90vw;
  }

  img {
    width: 100%;
    height: 100%;
    filter: blur(0px) brightness(70%);

    /* position: absolute;  */
  }
`;

const StyledContent = styled.div`
  position: absolute;
  top: 11rem;
  left: 8rem;
  right: 8rem;
  display: flex;
  flex-direction: column;
  max-width: fit-content;

  @media (max-width: 600px) {
    left: 0;
    right: 0;
    align-self: center;
    padding-left: 1.5rem;
  }

  button {
    margin: 0;
  }

  h1 {
    font-size: 4rem;
    /* height: 200px;  */
    color: white;
    font-weight: 900;
  }
  p {
    font-size: 1.2rem;
    /* height: 200px;  */
    color: white;
    font-weight: 500;
    /* width: 800px; */
    margin-bottom: 1rem;
  }
`;

export default Hero;
