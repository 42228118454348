import React, { useState } from "react";
import Review from "./Review.js";
import {
  CarouselProvider,
  Slider,
  Slide,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

export default class extends React.Component {
  render() {
    return (
      <div style={{ width: "100%", padding: "0px 30px" }}>
        <CarouselProvider
          style={{ height: "200px" }}
          totalSlides={5}
          visibleSlides={1}
          infinite={true}
          isPlaying={true}
          interval={4000}
          isIntrinsicHeight={true}
        >
          <Slider>
            <Slide index={0}>
              <Review
                text={"Very professional service. Made my lawn look excellent."}
                reviewer={"Ng Gabriel"}
              />
            </Slide>
            <Slide index={1}>
              <Review
                text={
                  "Excellent job on cutting my lawn. Everything looks neat and tidy."
                }
                reviewer={"Nina Sherle"}
              />
            </Slide>
            <Slide index={2}>
              <Review
                text={"He is a keeper and I highly recommend his service."}
                reviewer={"Akoo Hirji"}
              />
            </Slide>
            <Slide index={3}>
              <Review
                text={"Excellent service, great communication."}
                reviewer={"Ray Shum"}
              />
            </Slide>
            <Slide index={4}>
              <Review
                text={"Very good service!"}
                reviewer={"Albert Cheung"}
              />
            </Slide>
          </Slider>
        </CarouselProvider>
      </div>
    );
  }
}
