import React, { useState } from "react";
import styled from "styled-components";

const Services = ({ title, imageUrl, description, full }) => {
  const [showDescription, toggleDescription] = useState(false);
  return (
    <ServiceContainer
      onMouseEnter={() => toggleDescription(true)}
      onMouseLeave={() => toggleDescription(false)}
      style={{
        background: `linear-gradient(to bottom,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(121, 120, 120, 0.42) 100%), url(${imageUrl}) no-repeat center`,
      width: `${full ? '100vw' : '20rem'}`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      }}
    >
      {!showDescription ? <h2>{title}</h2> : <p>{description}</p>}
    </ServiceContainer>
  );
};

const ServiceContainer = styled.div`
  background-size: fill;
  border: 10px solid white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
  color: White;
  text-align: center;
  /* width: 180px;
    height: 290px; */
  height: 22rem;
  align-items: center;

  p {
    padding: 0 2rem;
    color: white;
    font-size: 1rem;
  }
  h2 {
    padding: 0 2rem;
    color: white;
    font-size: 1.7rem;
    font-weight: 600;
  }
`;
export default Services;
