import React, { useState } from "react";
import {
  Col,
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import FormControl from "react-bootstrap/FormControl";
import { termsAndConditions } from "./CONSTANTS";
import { Alert } from "reactstrap";

let formattedTerms = termsAndConditions.split("\n").map((i) => {
  return (
    <p>
      {i}
      <br />
    </p>
  );
});

const SnowRemovalForm = () => {
  const [submitEnabled, toggleSubmitEnabled] = useState(false);
  const [termsAndConditionsOpened, toggleTerms] = useState(false);
  const [alertTriggered, triggerAlert] = useState(false);

  const checkTermsAgreement = () => {
    console.log('asdfdsad')
    if (!termsAndConditionsOpened || !submitEnabled) {
      console.log(alertTriggered)
      triggerAlert(true);
    }
  };

  return (
    <div>
      {alertTriggered && (
        <Alert color="danger">This is a danger alert — check it out!</Alert>
      )}
      <Container>
        <Col md={6}>
          <br />
          <h3>Xenith Lawn Care Sign Up</h3>
          <p style={{ fontStyle: "italic" }}>Please fill out the following:</p>
          <br />
        </Col>

        <Col md={6}>
          <Form name="snow-removal" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="snow-removal" />
            <FormGroup>
              <Label for="first-name">First Name:</Label>
              <Input
                size="sm"
                type="first-name"
                name="first-name"
                id="first-name"
              />
            </FormGroup>
            <FormGroup>
              <Label for="last-name">Last Name:</Label>
              <Input
                size="sm"
                type="last-name"
                name="last-name"
                id="last-name"
              />
            </FormGroup>
            <FormGroup>
              <Label for="address">Address where work will be performed:</Label>
              <Input size="sm" type="address" name="address" id="address" />
            </FormGroup>
            <a href="#" onClick={toggleTerms}>
              Terms and Conditions
            </a>
            {termsAndConditionsOpened && <div>{formattedTerms}</div>}
            <FormGroup check>
              <Label check>
                <Input
                  name="accepted-terms"
                  type="checkbox"
                  disabled={!termsAndConditionsOpened}
                  onClick={(event) => toggleSubmitEnabled(event.target.checked)}
                />{" "}
                I have read and agree to the terms and conditions
              </Label>
            </FormGroup>
            <br />
              <Button type="submit" size="lg" disabled={!submitEnabled}>
                Submit
              </Button>
          </Form>
        </Col>
      </Container>
    </div>
  );
};

export default SnowRemovalForm;
