import styled from "styled-components";

export const StyledButton = styled.button`
  width: 10rem;
  height: 3rem;
  border: none;
  background: #2676ba;
  color: white;
  margin-left: 1rem;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const UpperCase = styled.p`
  text-transform: uppercase;
  font-weight: 600;
`;

export const termsAndConditions = `
1. The client agrees to the services as described on the website, and Xenith Lawn Care Inc. (“Xenith”) is permitted to occasionally modify these descriptions.\n
2. If the snow begins to fall between 10:01 a.m. and 4:00 a.m. (an 18-hour window) Xenith will clear it by 10 a.m. If, however, it begins to snow between 4:01 a.m. and 10 a.m. (a 6-hour window) Xenith will clear the snow as soon as possible and guarantees it will be cleared by 10 a.m. the following day. In either scenario there may, of course, be obstacles beyond Xenith’s control—e.g. road closures, extreme weather, equipment failure—that prevent Xenith from clearing snow by 10 a.m. Xenith will nevertheless do its best to work through these barriers and provide excellent service in a timely manner.\n
3. The removal of snowdrifts is not included in the monthly snow removal service, but for the sake of goodwill Xenith may choose to remove snowdrifts.\n
4. Xenith agrees to hold the necessary liability and WorkSafe insurance.\n
5. Xenith agrees to hold the client harmless for any damage or injury that may arise during the services, except if the client demonstrates gross negligence, willful misconduct, or bad faith. Similarly, the client agrees to hold Xenith harmless for any damage or injury that may arise during or after the services—including to third parties—except if Xenith demonstrates gross negligence, willful misconduct, or bad faith.\n
6. All prices advertised and quotes provided, either in writing or verbally, are subject to GST.\n
7. Payments for monthly snow removal are non-refundable.\n
8. Clients who cancel a yearly policy prematurely will be charged for the individual services already administered, and any outstanding balance will be due upon receipt.\n
9. These terms and conditions are in force so long as the client receives services from Xenith and apply perpetually to all services received in that period.\n
`;
